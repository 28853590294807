import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig, SortEvent } from 'primeng/api';
import { catchError, of } from 'rxjs';
import { AlertService } from 'src/app/service/alert.service';
import { EncodeDataService } from 'src/app/service/encode-data.service';
import { LogsService } from 'src/app/service/logs.service';
import { SharedDataService } from 'src/app/service/shared-data.service';

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'app-logs-page',
  templateUrl: './logs-page.component.html',
  styles: [
    `
        :host ::ng-deep .p-card{
            margin: 2%;
            color: #20BCC8;
            box-shadow: none;
        }
    `
],
  styleUrls: ['./logs-page.component.css']
})

export class LogsPageComponent implements OnInit {
  
  companies: any[] | undefined;
  selectedCompany: any = null;
  filteredCompanies: any[] = [];

  stores: any[] | undefined;
  selectedStore: any = null;
  filteredStores: any[] = [];

  startDate: any = null;
  endDate: any = null;

  checked = null;

  id_cliente = null;

  selectedMethod: any = null;
  Methods = [{ name: 'POST', code: 'POST' },
            { name: 'GET', code: 'GET' },
            { name: 'PUT', code: 'PUT' },
            { name: 'UPDATE', code: 'UPDATE' },
            { name: 'PATCH', code: 'PATCH' },
            { name: 'DELETE', code: 'DELETE' }
            ]  

  data: any = [];

  columns = [
    "Código Interfaz",
    "Objeto",
    "Llave de Objeto",
    "Parámetros",
    "Creación",
    "Error"
  ]

  first = 0;
  rows = 10;
    
  constructor(private logsService: LogsService, private sharedDataService: SharedDataService, private encodeData: EncodeDataService,
             private config: PrimeNGConfig, private router: Router, public alertService: AlertService) { }

  ngOnInit() {
    const empresa = this.sharedDataService.getEmpresa();
    this.getDetalle();
    this.getEmpresas();
    this.getTiendas(empresa);
    this.config.setTranslation({
      today: 'Hoy',
      clear: 'Limpiar',
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']
  });

  }

  getDetalle(){
    const empresa = this.sharedDataService.getEmpresa();
    const requestData = {
      empresa: empresa,
      tienda: null,
      IpCliente: null,
      MetodoSolicitud: null,
      fecha_inicio: null,
      fecha_fin: null,
      errorAuth: null
    };

    this.logsService.getDetalle(requestData)
    .pipe(
      catchError(error => {
        this.router.navigate(['/error-page']); 
        return of([]); 
      })
    ).subscribe({
      next: (data) => {
        if(data.status == 200){
          this.data = data.data;
          setTimeout(() => {
            this.alertService.closeSwal();
          }, 500);
        }else{
          this.data = data.data;
          this.alertService.closeSwal();
          this.alertService.showErrorAlert("No se encontraron datos con esos filtros.")
        }
        
      },
      error: (error) => {
        this.alertService.closeSwal();
        this.router.navigate(['/error-page']); 
      }
    });
  }

  onCompanyChange(selectedCompany: any) {
    this.getTiendas(selectedCompany.empresa);
  }
  
  getEmpresas(){
    const getEmpresa = {
      DB: this.sharedDataService.getDB()
    }

    this.logsService.getEmpresas(getEmpresa).subscribe({
      next: (data) => {
        this.companies = data.data.map((item: { empresa: any; nombre: any; }) => ({
          ...item,
          empresaNombre: `${item.empresa} - ${item.nombre}`
        }));
      },
      error: (error) => {
        this.alertService.showErrorAlert('Error al obtener empresas');
      }
    });
  }

  getTiendas(selectedCompany: any){
    const getTiendas = {
      empresa: this.selectedCompany?.empresa || selectedCompany,
      DB: this.sharedDataService.getDB()
    }
    
    this.logsService.getTiendas(getTiendas).subscribe({
      next: (data) => {
        this.stores = data.data.map((item: { tienda: any; empresa: any; nombre: any; }) => ({
          ...item,
          tiendaNombre: `${item.tienda} - ${item.nombre}`
        }));
      },
      error: (error) => {
        this.alertService.showErrorAlert('Error al obtener tiendas');
      }
    });
  }

  pageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
  }


  customSort(event: SortEvent) {
    if (event.data && event.field != null) { 
      event.data.sort((data1, data2) => {
        let value1 = data1[event.field as keyof typeof data1];
        let value2 = data2[event.field as keyof typeof data2];

        let result = null;
  
        if (value1 == null && value2 != null) result = -1;
        else if (value1 != null && value2 == null) result = 1;
        else if (value1 == null && value2 == null) result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string')
          result = value1.localeCompare(value2);
        else
          result = value1 < value2 ? -1 : (value1 > value2 ? 1 : 0);
  
        return event.order! * result;
      });
    } else {
      console.error('Unable to sort because event.data or event.field is undefined');
    }
  }
  
  display: boolean = false;
  jsonData: any; 
  header: String = "";

  mostrarJson(json: any, title: String) {
    this.header = title;
    this.jsonData = JSON.parse(json);
    this.display = true;
  }

  filterCompany(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query.toLowerCase();
  
    for (let i = 0; i < (this.companies as any[]).length; i++) {
      let company = (this.companies as any[])[i];
      if (company.empresaNombre.toLowerCase().indexOf(query) !== -1) {
        filtered.push(company);
      }
    }
  
    this.filteredCompanies = filtered;
  }
  
  filterStore(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query.toLowerCase();

    for (let i = 0; i < (this.stores as any[]).length; i++) {
        let store = (this.stores as any[])[i];
        if (store.tiendaNombre.toLowerCase().indexOf(query) !== -1) {
            filtered.push(store);
        }
    }

    this.filteredStores = filtered;
}


  aplicarFiltros() {
    this.alertService.showLoadingAlert();
    const empresa = this.sharedDataService.getEmpresa();
    const requestBody = {
      empresa: this.selectedCompany?.empresa || empresa,
      tienda: this.selectedStore?.tienda || null,
      IpCliente: this.id_cliente || null,
      MetodoSolicitud: this.selectedMethod?.code || null,
      fecha_inicio: this.formatDate(this.startDate) || null,
      fecha_fin: this.formatDate(this.endDate) || null,
      errorAuth: this.checked ? 1 : 0 || null
    };

    this.logsService.getDetalle(requestBody).subscribe({

      next: (data) => {
        if(data.status == 200){
          this.data = data.data;
          setTimeout(() => {
            this.alertService.closeSwal();
          }, 500);
        }else{
          this.data = data.data;
          this.alertService.closeSwal();
          this.alertService.showErrorAlert("No se encontraron datos con esos filtros.")
        }
        
      },
      error: (error) => {
        this.alertService.closeSwal();
        this.router.navigate(['/error-page']); 
      }
    });
  }

  formatDate(date: any) {
    if (!date) return null;
    const d = new Date(date);
    if (isNaN(d.getTime())) return null;

    const year = d.getFullYear();
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');
    const hour = `${d.getHours()}`.padStart(2, '0');
    const minute = `${d.getMinutes()}`.padStart(2, '0');
    const second = `${d.getSeconds()}`.padStart(2, '0');
    return `${year}-${day}-${month} ${hour}:${minute}:${second}`;
  }

  clear(){
    this.alertService.showLoadingAlert();
    this.selectedCompany = null;
    this.selectedStore = null;
    this.startDate  = null;
    this.endDate  = null;
    this.checked = null;
    this.id_cliente = null;
    this.selectedMethod = null;
    this.getDetalle();
  }


}
