import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EncodeDataService } from './encode-data.service';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  private logsUrl = environment.logsUrl;
  private reportsUrl = environment.Url + 'GetData';

  constructor(private http: HttpClient, private encodeData: EncodeDataService) { }

  getToken(data: any): Observable<any> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<any>(this.logsUrl + 'GetToken', data, { headers });
  }

  getDetalle(data: any): Observable<any> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<any>(this.logsUrl + 'getDetalle', data, { headers });
  }

  getTiendas(data: any): Observable<any> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<any>(this.logsUrl + 'Tienda', data, { headers });
  }

  getEmpresas(data: any): Observable<any> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<any>(this.logsUrl + 'Empresa', data, { headers });
  }
}
