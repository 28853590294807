import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  private empresa: string | null = null;
  private DB: string | null = null;
  private token: string | null = null;;

  constructor() { }

  setEmpresa(empresa: string) {
    this.empresa = empresa;
  }

  getEmpresa(): string | null {
    return this.empresa;
  }

  setToken(token: string): void {
    this.token = token;
  }

  getToken(): string | null{
      return this.token;
  }

  setDB(DB: string): void {
    this.DB = DB;
  }

  getDB(): string | null{
      return this.DB;
  }
  
}
