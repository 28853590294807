<div class="transform">

    <h2 style="margin-left: 2%;">Bitácora de Logs</h2>

    <p-card header="Filtros">

      <div class="formgrid grid">
        <div class="field col-4">
          <label for="Empresa">Empresa</label>
          <p-autoComplete (ngModelChange)="onCompanyChange($event)" [(ngModel)]="selectedCompany" [dropdownIcon]="'pi pi-search'" [dropdown]="true" [suggestions]="filteredCompanies" (completeMethod)="filterCompany($event)" field="empresaNombre" [forceSelection]="true"></p-autoComplete>
        </div>
        <div class="field col-4">
          <label for="Tienda">Tienda</label>
          <p-autoComplete [(ngModel)]="selectedStore" [dropdownIcon]="'pi pi-search'" [dropdown]="true" [suggestions]="filteredStores" (completeMethod)="filterStore($event)" field="tiendaNombre" [forceSelection]="true"></p-autoComplete>
        </div>
        <div class="field col-4">
          <label for="id">ID Cliente</label>
          <input type="text" [(ngModel)]="id_cliente" pInputText class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
        </div>
      </div>
      

    <div class="formgrid grid">
      <div class="field col">
          <label for="metodo">Método</label>
          <p-dropdown [options]="Methods" [(ngModel)]="selectedMethod" optionLabel="name" [showClear]="true" styleClass="method" placeholder="Selecciona un método"></p-dropdown>
      </div>
      <div class="field col">
          <label for="fecha inicio">Fecha y hora inicio</label>
          <p-calendar [(ngModel)]="startDate" [showIcon]="true"  [showButtonBar]="true" [showTime]="true"></p-calendar>
      </div>
      <div class="field col">
        <label for="fecha fin">Fecha y hora fin</label>
        <p-calendar [(ngModel)]="endDate" [showIcon]="true" [showButtonBar]="true" [showTime]="true"></p-calendar>
      </div>
  </div>
      
  <div class="card flex justify-content-left">
    <label>¿Es error?&nbsp;&nbsp;&nbsp;</label>
    <p-inputSwitch [(ngModel)]="checked"></p-inputSwitch>
</div>

    </p-card>


    <div class="buttons">
      <button class="custom-btn-clean" (click)="clear()"><b>Limpiar</b></button>
      <button class="custom-btn" (click)="aplicarFiltros()"><b>Aplicar</b></button>
    </div>

    
    <p-card header="Bitácoras">
        <p-table [columns]="columns" 
                [value]="data" 
                class="custom-border custom-vertical-border" 
                (sortFunction)="customSort($event)" 
                [customSort]="true" 
                [paginator]="true"
                [rows]="10"
                [first]="first"
                (onPage)="pageChange($event)"
                [rowsPerPageOptions]="[10, 25, 50]"
                [scrollable]="true">

            <ng-template pTemplate="header" let-columns>
              <tr>
                <th pFrozenColumn></th>
                <th>Empresa</th>
                <th pSortableColumn="tienda">Tienda  <i class="pi pi-code icono-rotado" style="color: #20BCC8"></i></th>
                <th *ngFor="let col of columns">
                  {{ col }}
                </th>
                <th pSortableColumn="metodo">Método  <i class="pi pi-code icono-rotado" style="color: #20BCC8"></i></th>
                <th>Datos JSON</th>
                <th>Codigo HTTP</th>
                <th>Datos JSON Respuesta</th>
                <th>API</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-value>
                <tr>
                    <td pFrozenColumn>
                        <span *ngIf="value.error_Auth == 'False'; else noError"><i class="pi pi-check-circle" style="font-size: 1.2rem; color: rgb(28, 228, 28);"></i></span>
                        <ng-template #noError><i class="pi pi-times-circle" style="font-size: 1.2rem; color: rgb(235, 10, 10);"></i></ng-template>
                    </td>
                    <td>{{ value.empresa }}</td>
                    <td>{{ value.tienda }}</td>
                    <td>{{ value.codigoInterfaz }}</td>
                    <td>{{ value.objeto }}</td>
                    <td>{{ value.key_objeto }}</td>
                    <td class="celda-centrada">
                        <button class="custom-button" (click)="mostrarJson(value.parametros, 'Parámetros')">
                          <i class="pi pi-eye" style="font-size: 1.5rem"></i>
                        </button>
                    </td>                  
                    <td>{{ value.creacion }}</td>
                    <td class="celda-centrada">
                      <button class="custom-button" (click)="mostrarJson(value.error, 'Error')">
                        <i class="pi pi-eye" style="font-size: 1.5rem"></i>
                      </button>
                    </td>  
                    <td>{{ value.metodo }}</td>
                    <td class="celda-centrada">
                      <button class="custom-button" (click)="mostrarJson(value.datos_solicitud, 'Datos JSON')">
                        <i class="pi pi-eye" style="font-size: 1.5rem"></i>
                      </button>
                    </td>  
                    <td>{{ value.codigo_http }}</td>
                    <td class="celda-centrada">
                      <button class="custom-button" (click)="mostrarJson(value.datos_respuesta, 'Datos JSON respuesta')">
                        <i class="pi pi-eye" style="font-size: 1.5rem"></i>
                      </button>
                    </td>  
                    <td>{{ value.Api }}</td>
                </tr>
            </ng-template>
          </p-table>
          
    </p-card>
</div>    


<p-dialog header="Header" [(visible)]="display" [modal]="true" [style]="{ width: '50rem' }" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <h2>{{header}}</h2>
        </div>
    </ng-template>
    <pre *ngIf="jsonData">{{ jsonData | json }}</pre>
</p-dialog>
