import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { SharedDataService } from './shared-data.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private excludedRoutes: string[] = [
        '/GetToken'
    ];

    constructor(private sharedService: SharedDataService) {} 

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.excludedRoutes.some(url => req.url.includes(url))) {
          return next.handle(req);
        }
      
        const authToken = this.sharedService.getToken();
        if (authToken) {
          const authReq = req.clone({
            headers: req.headers.set('token', authToken)
          });
          return next.handle(authReq);
        } else {
          return throwError(() => new Error('No authentication token'));
        }
      }
      
    
    
}
