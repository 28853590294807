import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LogsService } from '../service/logs.service';
import { SharedDataService } from '../service/shared-data.service';
import { EncodeDataService } from '../service/encode-data.service';
import { environment } from 'src/environments/environment';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { AlertService } from '../service/alert.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  resp!: boolean;
  private tokenProcessed = false;

  constructor(private router: Router, private logsService: LogsService, private sharedDataService: SharedDataService, private encodeData: EncodeDataService,
              private alertService: AlertService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
      if (this.tokenProcessed) {
        this.tokenProcessed = false;
        return true;
      }
  
      const token = next.queryParams['token'];
      if (token) {
        return this.validateToken(token).pipe(
          tap(valid => {
            if (!valid) {
              this.router.navigate(['/error-page']);
            } else {
              this.clearQueryParams();
              this.tokenProcessed = true;
            }
          }),
          catchError(error => {
            this.router.navigate(['/error-page']);
            return of(false);
          })
        );
      } else {
        this.router.navigate(['/error-page']);
        return false;
      }
  }

  private clearQueryParams() {
    const url = this.router.url.split('?')[0];
    this.router.navigateByUrl(url, { replaceUrl: true });
  }
  
  validateToken(token: string): Observable<boolean> {
    this.alertService.showLoadingAlert();
    const decodedObject = this.encodeData.decodeBase64ToJson(token);
    if (decodedObject != null) {
      this.sharedDataService.setEmpresa(decodedObject.empresa);
      this.sharedDataService.setDB(decodedObject.DB);
      const data = {
        empresa: decodedObject.empresa,
        DB: decodedObject.DB,
        usuario: decodedObject.usuario
      };
      return this.logsService.getToken(data).pipe(
        map(data => {
          if (data.status == 200) {
            this.sharedDataService.setToken(data.data[0].Token);
            return true;
          } else {
            this.router.navigate(['/error-page']);
            this.alertService.closeSwal();
            return false;
          }
        }),
        catchError(error => {
          this.router.navigate(['/error-page']);
          this.alertService.closeSwal();
          return of(false);
        })
      );
    } else {
      this.router.navigate(['/error-page']);
      this.alertService.closeSwal();
      return of(false);
    }
  }

}
