import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EncodeDataService {

  constructor() { }

  decodeBase64ToJson(base64String: any) {
    try {
      const text = atob(base64String);
      const obj = JSON.parse(text);
      return obj;
    } catch (error) {
      console.error("Error al decodificar y deserializar base 64", error);
      return null;
    }
  }

  jsonToBase64(json: any) {
    try {
      const jsonString = JSON.stringify(json);
      const base64String = btoa(jsonString);
      return base64String;
    } catch (error) {
      console.error("Error al codificar JSON a base 64", error);
      return null;
    }
  }
}
