// alert.service.ts
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor() { }

  public showLoadingAlert() {
    Swal.fire({
      title: 'Cargando...',
      text: 'Por favor, espera.',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
  
        const container = Swal.getContainer();
        if (container) {
          container.style.background = 'rgba(0, 14, 75, 0.8)';
          const loader = container.querySelector('.swal2-loader') as HTMLElement;
          if (loader !== null) {
            loader.style.width = '220px';
            loader.style.height = '220px';
            loader.style.border = '15px solid #51BFE7';
            loader.style.borderRadius = '50%';
            loader.style.borderRightColor = '#FFFFFF';
            loader.style.borderBottomColor = '#FFFFFF';
            loader.style.borderLeftColor = '#FFFFFF';
            loader.style.margin = 'auto';
          }
        }

        const textContainer = Swal.getHtmlContainer();
        if (textContainer) {
          textContainer.style.fontSize = '25px';
        }

        const title = Swal.getTitle();
        if (title) {
          title.style.fontSize = '50px';
        }

        const popup = Swal.getPopup();
        if (popup) {
          popup.style.backgroundColor = 'rgba(0, 14, 75, 0)';
          popup.style.color = '#FFFFFF';

          popup.querySelectorAll('*').forEach((child: Element) => {
            (child as HTMLElement).style.color = '#FFFFFF';
          });
        }
      }
    });
  }

  public closeSwal() {
    Swal.close();
  }

  public showErrorAlert(errorMsg: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: errorMsg
    });
  }

// public showLoadingAlert() {
  //   Swal.fire({
  //     title: 'Cargando...',
  //     allowOutsideClick: false,
  //     didOpen: () => {
  //       Swal.showLoading();
  
  //       const container = Swal.getContainer();
  //       if (container) {
  //         container.style.background = 'rgba(0, 14, 75, 0.8);';
  //         const loader = container.querySelector('.swal2-loader') as HTMLElement;
  //         if (loader !== null) {
  //           loader.style.width = '220px';
  //           loader.style.height = '220px';
  //           loader.style.border = '15px solid #51BFE7';
  //           loader.style.borderRadius = '50%';
  //           loader.style.borderRightColor = '#FFFFFF';
  //           loader.style.borderBottomColor = '#FFFFFF';
  //           loader.style.borderLeftColor = '#FFFFFF';
  //           loader.style.margin = 'auto';
  //         }
  //       }

  //       const textContainer = Swal.getHtmlContainer();
  //       if (textContainer) {
  //         textContainer.style.fontSize = '25px';
  //       }

  //       const title = Swal.getTitle();
  //       if (title) {
  //         title.style.fontSize = '50px';
  //       }

  //       const popup = Swal.getPopup();
  //       if (popup) {
  //         popup.style.background = 'rgba(0, 14, 76, 0);';
  //         popup.style.color = '#FFFFFF';

  //         popup.querySelectorAll('*').forEach((child: Element) => {
  //           (child as HTMLElement).style.color = '#FFFFFF';
  //         });
  //       }
  //     }
  //   });
  // }

}
